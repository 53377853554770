import React from "react";
import {Routes, Route, Link} from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import PrivateSalePage from "./pages/PrivateSalePage";
import {app, Release, Stage} from "./index";
import {getAnalytics} from "firebase/analytics";
import PrivateSalePage2 from "./pages/PrivateSalePage2";
import PrivateSalePage3 from "./pages/PrivateSalePage3";


function App() {
    console.log("website version {"+ Stage +"}:", Release);
    const analytics = getAnalytics(app);
    return (
        <Routes>
            <Route path="/" element={<Home/>}/>
        </Routes>
    );
}

export default App;
