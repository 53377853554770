import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter} from "react-router-dom";
import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import {MoralisProvider} from 'react-moralis';
import {
    getServerInfo, IServerPackage,
    ServerState
} from "./configs/settings";
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import LoggerComponent, {LoggerModes} from "./components/loggerComponent/loggerComponent";


// SET NETWORK TYPE HERE
const version = "build 10";
const serverState = ServerState.MainNet;
const serverInfo: IServerPackage = getServerInfo(serverState, version);

const firebaseConfigMain = {
    apiKey: "AIzaSyDPlXJERNX8Jd7K8Fm8XfZM8x0mcuCzCl0",
    authDomain: "pepperbirdfinance.firebaseapp.com",
    projectId: "pepperbirdfinance",
    storageBucket: "pepperbirdfinance.appspot.com",
    messagingSenderId: "430311518493",
    appId: "1:430311518493:web:d6da1ecc67d4df645bf1f4",
    measurementId: "G-M89WYD8RS0"
};

const firebaseConfigTest = {
    apiKey: "AIzaSyDPlXJERNX8Jd7K8Fm8XfZM8x0mcuCzCl0",
    authDomain: "pepperbirdfinance.firebaseapp.com",
    projectId: "pepperbirdfinance",
    storageBucket: "pepperbirdfinance.appspot.com",
    messagingSenderId: "430311518493",
    appId: "1:430311518493:web:2120b70954837b6f5bf1f4",
    measurementId: "G-G4VV6JTXK4"
};

function getFirebaseConfig() {
    let firebaseConfig: any;
    if (serverInfo.type == 'production') {
        firebaseConfig = firebaseConfigMain;
    } else {
        firebaseConfig = firebaseConfigTest;
    }

    return firebaseConfig;
}

export const app = initializeApp( getFirebaseConfig());
export const analytics = getAnalytics(app);


export const MoralisAppID = serverInfo.moralisAppId;
export const MoralisServer = serverInfo.moralisServer;
export const RPCNetwork = serverInfo.networkRPC;
export const NetworkChainID = serverInfo.networkChainId;
export const PrivateSaleContract = serverInfo.privateSaleContract;
export const Release = serverInfo.release;
export const Stage = serverInfo.type;
export const BSC_SCAN_URL = serverInfo.chainLookup;
export const PrivateSaleContract2 = '0xc75767B2eC3505033E0a1FD951B1e1AA033292a2';
export const PrivateSaleContract3 = '0xfa06819B56F52Ab6b4D36CC8ac729CB4cfbd6979';


export const log = new LoggerComponent(LoggerModes.PROD_MODE);

LogRocket.init('v37quo/pepperbird-finance');
setupLogRocketReact(LogRocket);

Sentry.init({
    dsn: "https://89be5b32f9a74b68bc085464c3b0d9d7@o1179624.ingest.sentry.io/6291888",
    integrations: [new BrowserTracing()],
    release: Release,
    environment: Stage,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: .1,
});

LogRocket.getSessionURL(sessionURL => {
    Sentry.configureScope(scope => {
        scope.setExtra("sessionURL", sessionURL);
    });
});

//export const log = new LoggerComponent();
//log.initGoogleCloudLogging('pepperbirdfinance','testLog');
// Imports the Google Cloud client library


ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <MoralisProvider appId={MoralisAppID} serverUrl={MoralisServer}>
                <App/>
            </MoralisProvider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
