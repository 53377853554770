import { initializeApp } from "firebase/app";


const firebaseConfig = {
    apiKey: "AIzaSyDPlXJERNX8Jd7K8Fm8XfZM8x0mcuCzCl0",
    authDomain: "pepperbirdfinance.firebaseapp.com",
    projectId: "pepperbirdfinance",
    storageBucket: "pepperbirdfinance.appspot.com",
    messagingSenderId: "430311518493",
    appId: "1:430311518493:web:d6da1ecc67d4df645bf1f4",
    measurementId: "G-M89WYD8RS0"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);