import React from "react";
import {Release, Stage} from "../index";
import {outGoingLink} from "../functions/outGoingLink";

const Footer = () => {
  // @ts-ignore
  return (
    <footer className="footer-2">
      <div className="container">
        <div className="row justify-content-center">
          <div className="footer-block">
            <div className="sec-title text-center">
              <h4 className="widget-title">Join us on Social!</h4>
            </div>
            <p>
              Join one or all of our social platforms and keep up with new
              developments.
            </p>
          </div>
          <div className="col-sm-12">
            <div className="socials">
              <ul>
                <li>
                  <a onClick={()=> outGoingLink('https://www.t.me/pepperbird', 'TELEGRAM_Clicked',false)}>
                    <i className="fab fa-telegram-plane"></i>
                  </a>
                </li>
                <li>
                  <a className="twitter" onClick={()=> outGoingLink('https://twitter.com/pepperbirdtoken', 'TWITTER_Clicked',false)}>
                    <i className="fab fa-twitter"></i>
                  </a>
                </li>
                <li>
                    <a className="discord" onClick={()=> outGoingLink('https://discord.gg/7FnvFwgfhX', 'DISCORD_Clicked',false)}>
                    <i className="fab fa-discord"></i>
                  </a>
                </li>
             <li>
                 <a className="linkedin" onClick={()=> outGoingLink('https://linkedin.com/company/pepperbird-finance', 'LINKEDIN_Clicked',false)}>
                 <i className="fab fa-linkedin-in"></i>
               </a>
             </li>
           <li>
               <a className="instagram" onClick={()=> outGoingLink('https://instagram.com/pepperbirdtoken', 'INSTAGRAM_Clicked',false)}>
               <i className="fab fa-instagram"></i>
             </a>
           </li>
              <li>
                  <a className="youtube" onClick={()=> outGoingLink('https://m.youtube.com/channel/UC3c55RUJff9QzCOSaXewlDA', 'YOUTUBE_Clicked',false)}>
                  <i className="fab fa-youtube"></i>
                </a>
              </li>
             <li>
                 <a className="medium" onClick={()=> outGoingLink('https://blog.pepperbird.finance', 'BLOG_Clicked',false)}>
                 <i className="fab fa-medium-m"></i>
               </a>
             </li>
              </ul>
            </div>
            <div className="copyrights">© 2022 Pepperbird Finance | ({Stage}): {Release}</div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
