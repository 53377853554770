
export enum LoggerModes {
    DEV_MODE,
    PROD_MODE,
    MANUAL,
}


class LoggerComponent {

    private isConsoleLogging: any;
    private isInfoLogging: any;
    private isWarnLogging: any;
    private  isDebugLogging: any;
    private  isErrorLogging: any;

    constructor(_mode: LoggerModes) {
        this.isConsoleLogging = false;
        this.isInfoLogging = false;
        this.isDebugLogging = false;
        this.isWarnLogging = false;
        this.isErrorLogging = true;

        if (_mode == LoggerModes.DEV_MODE) { this.enableDevMode()}
        if (_mode == LoggerModes.PROD_MODE) {this.enableProductionMode()}
    }

    disableConsole() { this.isConsoleLogging = false }
    enableConsole() { this.isConsoleLogging = true }

    disableInfo() {this.isInfoLogging = false}
    enableInfo() {this.isInfoLogging = true}

    disableDebug() {this.isDebugLogging = false}
    enableDebug() {this.isDebugLogging = true}

    disableWarn() {this.isWarnLogging = false}
    enableWarn() {this.isWarnLogging = true}

    disableError() {this.isErrorLogging = false}
    enableError() {this.isErrorLogging = true}

    enableAllModes() {
        this.isInfoLogging = true;
        this.isErrorLogging = true;
        this.isDebugLogging = true;
        this.isWarnLogging = true;
    }

    enableProductionMode() {
        this.isConsoleLogging = true;
        this.disableAllModes();
        this.isErrorLogging = true;
    }

    enableDevMode() {
        this.enableAllModes();
        this.isConsoleLogging = true;
    }

    disableAllModes() {
        this.isInfoLogging = false;
        this.isErrorLogging = false;
        this.isDebugLogging = false;
        this.isWarnLogging = false;
    }

    info(...log: any[]) {
        if (this.isInfoLogging) {
            if (this.isConsoleLogging) {
                console.info(log);
            }
        }
    }

    warn(...log: any[]) {
        if (this.isWarnLogging) {
            if (this.isConsoleLogging) {
                console.warn(log);
            }
        }
    }

    debug(...log: any[]) {
        if (this.isDebugLogging) {
            if (this.isConsoleLogging) {
                console.debug(log);
            }
        }
    }

    error(...log: any[]) {
        if (this.isErrorLogging) {
            if (this.isConsoleLogging) {
                console.info(log);
            }
        }
    }

}

export default LoggerComponent;